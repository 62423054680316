<template>
  <v-container>
    <v-row v-if="connected && !curNode">
      Please select a node to create a match..
    </v-row>
    <v-row v-else-if="connected">
      <v-col cols="2">
        <v-select
          v-model="joinRegion"
          :items="regions"
          label="Region"
          outlined
        />
      </v-col>
      <v-col>
        <v-select
          v-model="joinMode"
          :items="nodeModes"
          label="Mode"
          outlined
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="maxPlayers"
          label="Max Players"
        />
      </v-col>
      <v-col class="d-flex justify-start align-baseline">
        <v-btn @click="doCreateMatch">
          Create Match
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox v-model="cbShowExpired" label="Show Expired" />
      </v-col>
    </v-row>
    <v-row @click="web3Test2">
      <v-col>
        <v-chip-group
          active-class="primary"
          mandatory
        >
          <v-chip
            v-for="(g, index) of oracleList"
            :key="index"
            @click="selectNode(g.address)"
          >
            {{ g.name }}
          </v-chip>
        </v-chip-group>
        Total Invites: {{ invites.length }}
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="!invites">
        Loading..
      </v-col>
      <v-col
        v-for="invite of filteredInvites"
        v-else
        :key="invite.id"
        cols="12"
        md="4"
        lg="3"
        xl="3"
      >
        <InviteCard :invite="invite" :bets="betList(invite.gameId)" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {
    mapState,
    mapGetters,
    mapActions,
  } from 'vuex'
  import InviteCard from '../../components/invites/InviteCard.vue'
  export default {
    name: 'Partners',
    components: { InviteCard },

    data () {
      return {
        curNode: null,
        cbShowExpired: false,
        joinAlias: 'alias',
        joinValue: 100,
        partners: {},
        tInvites: null,
        joinRegion: '',
        joinMode: 0,
        maxPlayers: 8,
      }
    },
    computed: {
      nodeModes () {
        var ret = []
        if (!this.curNode) return ret
        var o = this.oracles.find(o => o.address === this.curNode)
        if (!o.modes) return ret
        for (var value = 0; value < o.modes.length; value++) {
          console.log('vv', o.modes, value)
          ret.push({
            value,
            text: o.modes[value].name,
          })
        }
        return ret
      },
      oracleList () {
        if (!this.oracles.length) return [{ name: 'All' }]
        return [{ name: 'All' }, ...this.oracles]
      },
      ...mapGetters('rpc', ['invites']),
      ...mapState('wallet', ['platformContract', 'connected', 'contract']),
      ...mapState('rpc', ['web3', 's_bets']),
      ...mapState('platform', ['oracles', 'timeTick']),
      filteredInvites () {
        var filter = []
        for (var inv of Object.values(this.invites)) {
          console.log('inv', inv)
          if (inv.state !== 2 &&
            (this.cbShowExpired || parseInt(inv.expireTime) > (new Date()).getTime() / 1000) &&
            (!this.curNode || inv.oracle === this.curNode)) filter.push(inv)
        }
        return filter
      },
      regions () {
        var ret = []
        if (!this.curNode) return ret
        var o = this.oracles.find(o => o.address === this.curNode)
        if (!o.regions) return ret
        for (var region of Object.keys(o.regions)) {
          ret.push({
            value: region,
            text: o.regions[region],
          })
        }
        return ret
      },
    },
    async mounted () {
      /* var results = {}

      var r = await this.getLastMatchs(20)
      this.matchIds = r
      for (var gameId of r) {
        var info = await this.getMatch(gameId)
        var matchState = await this.getOracleMatchState(gameId)
        console.log('matchState', matchState)
        if (matchState.matchState !== '2') { // ok result..
          console.log('gi', info)
          info.gameId = gameId
          info.matchState = matchState
          results[gameId] = info
        }
      }
      this.tInvites = results

      this.getInvites() */
    },
    methods: {
      betList (gameId) {
        var ret = []
        for (var bet of this.s_bets) {
          if (bet.gameId === gameId) {
            ret.push(bet)
          }
        }
        return ret
      },
      selectNode (node) {
        this.curNode = node
      },
      ...mapActions('rpc', ['getBets', 'getLastMatchs', 'createMatch', 'getMatch',
                            'getOracleMatchState']),
      ...mapActions('platform', ['getOracles']),
      async web3Test () {
        console.log('dow3 test')
        var gameIds = await this.platformContract.methods.getLastMatchs(20).call()
        for (var gameId of gameIds) {
          console.log('z', gameId)
          var info = await this.platformContract.methods.getMatch(gameId).call()
          console.log('info', info)
        }
      },
      web3Test2 () {
        var self = this
        console.log('dow3 test')
        const avgBlockTime = 2 // 2 seconds
        var lastBlock = 0
        this.web3.eth.getBlockNumber().then(async curBlockN => {
          // console.log('do req block n', curBlockN)
          if (lastBlock === 0) {
            var nBlocks = ((60 * 60 * 24) / avgBlockTime)
            // if (nBlocks > 5000) nBlocks = 5000
            lastBlock = curBlockN - nBlocks
          }
          var fromBlock = lastBlock

          var invs = {}
          await this.platformContract.getPastEvents('MatchCreated', {
            fromBlock,
            toBlock: curBlockN,
          }, async function (error, events) {
            if (error) return console.log('err', error)
            lastBlock = curBlockN
            console.log('got event1..', events)
            for (var event of events) {
              const blockData = await self.web3.eth.getBlock(event.blockNumber)
              var time = blockData.timestamp
              // ..
              var { gameId, maxPlayer, method, mode, oracle, region, sender } = event.returnValues
              var e = { gameId, maxPlayer, method, mode, oracle, region, sender, time }
              invs[gameId] = e
            }
            // console.log('invs', self.tInvites)
          })
          await this.platformContract.getPastEvents('BetCreated', {
            fromBlock,
            toBlock: curBlockN,
          }, async function (error, events) {
            if (error) return console.log('err', error)
            console.log('got event2..', events)
            for (var event of events) {
              const blockData = await self.web3.eth.getBlock(event.blockNumber)
              var time = blockData.timestamp
              var { oracle, sender, gameId, player, total } = event.returnValues
              var invite = invs[gameId]
              if (invite && invite.oracle === oracle) {
                var nChop = 1
                while (player.substr(-nChop, 1) === '0') {
                  nChop++
                }
                player = self.web3.utils.toAscii(player.substr(0, player.length - nChop + 1))

                console.log('goocii')
                if (!invite.bets) invite.bets = []
                invite.bets.push({ time, sender, player, total })
              }
            }
            self.tInvites = invs
          })

          // get past events
          // sign for new events?
        })
      },
      isSender (match) {
        return match.sender === this.address
      },
      isBetting (match) {
        return match.bets && match.bets.find(b => b.owner === this.address)
      },
      ...mapActions('wallet', ['sendMsg']),
      // ...mapActions('platform', ['getInvites']),
      async doCreateMatch () {
        if (this.regions.length && !this.joinRegion) return alert('please select region!')
        if (!this.curNode) return alert('please select node!')
        var maxPlayers = 8
        var region = this.joinRegion
        console.log('reg', this.joinRegion)
        var mode = '0'
        var token = '0x8a2cF6277af2dbE6d870423C3D6d2232ea30D845' // GGT
        var c = await this.createMatch({ node: this.curNode, token, maxPlayers, region, mode, shareMethod: '0' })
        console.log('create match result', c)
      },
    },
  }
</script>
